import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container } from "react-bootstrap"
import LinesEllipsis from 'react-lines-ellipsis'

const ArticlesPage = ({data}) => (
  <Layout pageInfo={{ pageName: "publication" }} active="article">
    <SEO title="Articles" keywords={[`unhistoried`, `article`, `brrrlabs`]} />
    <Container className="articles-page">
        {data.allContentfulArticle.nodes.map((item, index) => {
          return (
            <Link to={"/article/"+item.articleTitle}>
            <Row className="unhistoried-articles-list-row">
              <Col md={4} className="unhistoried-articles-col">
                <GatsbyImage image={item.articleFeaturedImage.gatsbyImageData}  />
              </Col>
              <Col md={8} className="unhistoried-articles-col">
                <h2 className="unhistoried-articles-title">{item.articleTitle}</h2>
                  <h5 className="unhistoried-articles-overview">
                  <LinesEllipsis
                    text={JSON.parse(item.articleContent.raw).content[0].content[0].value}
                    maxLine='3'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                  />
                  </h5>
                <h5>Read more... ⟶</h5>
              </Col>
            </Row>
            </Link>
          )
        })}
    </Container>
  </Layout>
)

export const articlesQuery = graphql`
query ArticlesQuery {
  allContentfulArticle(sort: {order: DESC, fields: createdAt}) {
    nodes {
      articleTitle
      articleFeaturedImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
      articleContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            __typename
          }
        }
      }
    }
  }
}`

export default ArticlesPage
